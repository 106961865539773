export { PARTNER } from "./partner";

export type { PartnerType } from "./partner";

export const SEO_REDIRECTIONS = {
  CARD_LOAD:
    "https://tripmoney.makemytrip.com/mweb/partners/mmt/forex/bmf/dashboard?",
  CARD_BUY:
    "https://tripmoney.makemytrip.com/partners/mmt/forex/bmf/onboarding/load-currency?",
  CURRENCY_BUY:
    "https://tripmoney.makemytrip.com/partners/mmt/forex/bmf/currency/onboarding/load-currency?",
};

export const PRODUCT_TYPE = {
  CARD: "card",
  CURRENCY: "currency",
};

export const CASHBACK_STATUS = {
  COMMITTED: "COMMITTED",
  CANCELLED: "CANCELLED",
  CREDITED: "CREDITED",
  AVAILABLE: "AVAILABLE",
};

export const CASHBACK_TEXT: { [key: string]: string } = {
  [CASHBACK_STATUS.COMMITTED]: "Applied",
  [CASHBACK_STATUS.CANCELLED]: "Cancelled",
  [CASHBACK_STATUS.CREDITED]: "Credited",
  [CASHBACK_STATUS.AVAILABLE]: "Available",
};
export const PRODUCT_CODE = {
  CARD: "PC",
  CURRENCY: "CN",
};

export const IOS = "ios";
