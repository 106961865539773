import {
  getDeviceDetailsObject,
  getHostName,
  getRequestIdHeaders,
  isAgentFlow,
  isPartnerHost,
  setPageContextHeaders,
} from "../utils";
import { getPartner } from "../utils/authUtils";
import { getCookie } from "../utils/cookieUtils";
import { loginIfPartnerLoggedin } from "@tm/auth";
import {
  APP_SOCIAL_URL,
  BASE_URL,
  GET_SLT_TOKEN_URL,
  HOST,
  PDT_BASE_URL,
  ERROR_CODE,
  DEVICE,
  SOURCE,
  COOKIES,
} from "../constants";
import { getSessionStorageItem } from "../utils/clientStorageUtils";
import { XpressRtDataTypes } from "../utils/xpressRtUtils";

interface FetchApiOptions {
  maxRetries?: number;
  retryDelay?: number;
  headers?: Record<string, string>;
  method?: string;
  data?: any;
}

const getHeaders = (url: string) => {
  const headers: any = {
    partner: getPartner() || "",
    credentials: "include",
  };
  if (
    url?.includes("/pdt") ||
    url?.includes(APP_SOCIAL_URL(getPartner())) ||
    url?.includes(GET_SLT_TOKEN_URL(getPartner())) ||
    url?.includes(getPdtBaseUrl())
  ) {
    return {};
  } else if (isPartnerHost(SOURCE.FOREX_EXPERT)) {
    headers["x-agent-auth"] =
      getCookie(COOKIES.FOREX_AGENT_AUTH_KEY, true) || "";
    headers["x-ticket-id"] = getSessionStorageItem("ticket_id", true) || "";
  } else {
    headers["x-tm-auth"] = getCookie("x-tm-auth", true) || "";
  }
  return headers;
};

export async function fetchApi(url: any, options: FetchApiOptions = {}) {
  const {
    maxRetries = 2,
    retryDelay = 1000,
    headers = {},
    method = "GET",
    data = null,
  } = options;
  let retries = 1;

  while (retries <= maxRetries) {
    try {
      const controller = new AbortController();
      const signal = controller.signal;
      const tmAuthHeaders: any = getHeaders(url);

      const deviceDetails: any =
        url?.includes("/pdt") ||
        url?.includes(APP_SOCIAL_URL(getPartner())) ||
        url?.includes(GET_SLT_TOKEN_URL(getPartner())) ||
        url?.includes(getPdtBaseUrl())
          ? {}
          : {
              [DEVICE]: JSON.stringify(
                getDeviceDetailsObject(navigator.userAgent, getPartner())
              ),
            };

      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          ...headers, // merge the provided headers with the default headers,
          ...tmAuthHeaders,
          ...deviceDetails,
        },
        body: data && JSON.stringify(data),
        signal,
      });

      if (response.status === 500) {
        throw new Error("Internal Server Error");
      }

      if (response.status === 404) {
        throw new Error("Resource Not Found");
      }

      if (
        response.status === 204 ||
        response.headers.get("Content-Length") === "0"
      ) {
        return;
      }

      const isTmAuthPresent = getCookie("x-tm-auth", true);
      const responseData = await response.json();
      if (!responseData?.success) {
        const error = responseData?.error;
        const { code } = error || "";
        if (code === ERROR_CODE.LOGIN_REQUIRED) {
          await loginIfPartnerLoggedin();
          retries++;
          continue;
        }
      } else if (
        responseData?.data?.is_auth_token_valid === false &&
        isTmAuthPresent &&
        !isAgentFlow()
      ) {
        await loginIfPartnerLoggedin();
        retries++;
        continue;
      }
      return responseData;
    } catch (error: any) {
      console.log("Error in fetchApi", error.message);
      if (error.name === "AbortError") {
        console.error("Request cancelled");
        throw new Error("Request cancelled");
      }

      throw new Error(error);
    }
  }

  throw new Error(`Error received in API ${url} after ${maxRetries} attempts.`);
}

export const fetchApiForexListing = async (url: string, options: any) => {
  try {
    if (shouldIncludeExternalHeaders(url)) {
      let headers: any = {};
      setPageContextHeaders(headers);
      options = {
        ...options,
        headers: { ...options.headers, ...headers, ...getRequestIdHeaders() },
      };
    }
    const baseUrl = getBaseUrl();
    url = baseUrl + url;
    const response = await fetchApi(url, options);
    return transformResponse(response);
  } catch (e: any) {
    throw new Error(e);
  }
};

function shouldIncludeExternalHeaders(url: string | null | undefined) {
  return url?.includes("/pdt") ||
    url?.includes(APP_SOCIAL_URL(getPartner())) ||
    url?.includes(GET_SLT_TOKEN_URL(getPartner())) ||
    url?.includes(getPdtBaseUrl())
    ? false
    : true;
}

export const sendPdtDataApi = async (data: any) => {
  try {
    const url = getPdtBaseUrl();
    const headers: any = {
      "Content-Type": "application/json",
      "Request-Data-Encoding": "json",
    };

    const response = await fetchApi(url, {
      headers: headers,
      method: `POST`,
      data: data,
    });
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

export const fetchApiCrossSellData = async (url: string, options: any) => {
  try {
    const baseUrl = getBaseUrl();
    url = baseUrl + url;
    const response = await fetchApi(url, options);
    return response;
  } catch (e: any) {
    throw new Error(e);
  }
};

export const postXpressRtData = async (
  url: string,
  data: Partial<XpressRtDataTypes>
) => {
  const baseUrl = getBaseUrl();
  url = baseUrl + url;
  const host = getHostName();
  if (!baseUrl && host === HOST.MAKEMYTRIP) {
    url = "https://" + HOST.TRIPMONEY_MAKEMYTRIP + url;
  }
  try {
    const response = await fetchApi(url, {
      method: "POST",
      data: data,
    });
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

function transformResponse(res: any) {
  const isTM = isTripmoneyHost();
  if (isTM) {
    return res;
  }
  // TODO: Transform skywalker response.
  return res;
}

function getBaseUrl() {
  const host = getHostName();
  switch (host) {
    case HOST.MAKEMYTRIP:
      // TODO: Return skywalker base url of MMT.
      return BASE_URL;

    case HOST.GOIBIBO:
      // TODO: Return skywalker base url of GI.
      return BASE_URL;
    default:
      return BASE_URL;
  }
}

function isTripmoneyHost() {
  const host = getHostName();
  return ![HOST.MAKEMYTRIP, HOST.GOIBIBO].includes(host || "");
}

function getPdtBaseUrl() {
  const host = getHostName();
  switch (host) {
    case HOST.MAKEMYTRIP:
      return "https://pdt.makemytrip.com/dts/s/da/n";
    default:
      return "https://pdt.makemytrip.com/dts/s/da/n";
  }
}
