import { getHostName, getPartnerFromUrl } from ".";
import {
  SESSION_STORAGE_KEYS,
  PERSUASION_TYPE,
  CARD_TYPE,
  deepLink,
  SIGNAL_STATE,
} from "../constants";
import { landingCardShowcaseDataProps } from "../static/mockData";
import { redirectInterceptHref } from "../static/utils";
import { removeSessionStorageItem } from "./clientStorageUtils";
import { IRedirectUrlOption } from "./redirectionUtilsV2";

function extractCommonRedirectionData(
  item: landingCardShowcaseDataProps,
  persuasionType: PERSUASION_TYPE
) {
  const persuasionCta = item.persuasions.find(
    (persuasion) => persuasion.type === persuasionType
  )?.cta;
  const nextSignalState =
    persuasionCta?.user_state_data?.next_signal_state ||
    item.cta?.user_state_data?.next_signal_state;
  const orderId =
    persuasionCta?.user_state_data?.order_id ||
    item?.cta?.user_state_data?.order_id;
  return { nextSignalState, orderId };
}

export const signalStateRedirectionV3 = (
  tmBaseUrl: string,
  mwebtmBaseUrl: string,
  signalState: any,
  data: {
    queryParam?: any;
    orderId?: string;
    signal_data?: any;
    feature_flag?: { solve_reload?: boolean };
  } = {},
  otuForexPartnerUrl: string | undefined
) => {
  const url = `${tmBaseUrl}/bmf/onboarding`;
  switch (signalState) {
    case SIGNAL_STATE.TRAVELLER_DETAILS:
      redirectInterceptHref(
        `${url}/traveller-details?deeplink=${deepLink.BMF_TRAVELLER_DETAILS}${
          data?.orderId ? "&dp_orderId=" + data?.orderId : ""
        }&source=listing`
      );
      break;
    case SIGNAL_STATE.DOCUMENT_PENDING:
      redirectInterceptHref(
        `${mwebtmBaseUrl}/uploadDocs?dp_orderId=${data.orderId}`
      );
      break;
    case SIGNAL_STATE.ORDER_UNDER_PROCESSING:
    case SIGNAL_STATE.ADD_PRODUCTS:
      redirectInterceptHref(
        `${url}/load-currency?deeplink=${deepLink.BMF_ADD_PRODUCTS}${
          data?.orderId ? "&dp_orderId=" + data?.orderId : ""
        }`
      );
      break;
    case SIGNAL_STATE.START_PAYMENT:
      if (data?.signal_data?.checkout_url) {
        redirectInterceptHref(`${data.signal_data.checkout_url}`);
      } else {
        redirectInterceptHref(`${url}/landing?source=listing`);
      }
      break;

    case SIGNAL_STATE.BMF_CARD_LANDING:
      redirectInterceptHref(`${url}/landing?source=listing`);
      break;

    case SIGNAL_STATE.BMF_PAYMENT_FAILED:
    case SIGNAL_STATE.BMF_PAYMENT_CONFIRMED:
    case SIGNAL_STATE.BMF_PAYMENT_BEING_CONFIRMED:
    case SIGNAL_STATE.BMF_DASHBOARD:
      if (data?.feature_flag?.solve_reload) {
        redirectInterceptHref(
          `${mwebtmBaseUrl}/dashboard/new${
            data.queryParam ? "?" + data.queryParam : ""
          }`
        );
        return;
      }
      redirectInterceptHref(
        `${mwebtmBaseUrl}/dashboard${
          data.queryParam ? "?" + data.queryParam : ""
        }`
      );
      break;

    case SIGNAL_STATE.BMF_ORDER_SUBMITTED:
      redirectInterceptHref(
        `${mwebtmBaseUrl}/documentSubmit?deeplink=${deepLink.BMF_ORDER_SUBMITTED}`
      );
      break;

    default:
      redirectInterceptHref(`${url}/load-currency?source=listing`);
      break;
  }
};

export const signalStateRedirectionForCurrencyV3 = (
  tmBaseUrl: string,
  mwebtmBaseUrl: string,
  signalState: any,
  data: { orderId?: string; signal_data?: any } = {},
  otuForexPartnerUrl: string | undefined
) => {
  const url = `${tmBaseUrl}/bmf/currency`;
  const partner = getPartnerFromUrl();
  switch (signalState) {
    case SIGNAL_STATE.TRAVELLER_DETAILS:
      redirectInterceptHref(`${url}/onboarding/load-currency?source=listing`);
      break;
    case SIGNAL_STATE.ADD_PRODUCTS:
      redirectInterceptHref(
        `${url}/onboarding/load-currency?${
          data?.orderId ? "dp_orderId=" + data?.orderId : ""
        }`
      );
      break;
    case SIGNAL_STATE.ORDER_UNDER_PROCESSING:
      redirectInterceptHref(
        `${url}/onboarding/traveller-details?${
          data?.orderId ? "dp_orderId=" + data?.orderId : ""
        }&source=listing`
      );
      break;
    case SIGNAL_STATE.DOCUMENT_PENDING:
      redirectInterceptHref(
        `${url}/post-sales/upload-documents/${data?.orderId}`
      );
      break;
    case SIGNAL_STATE.START_PAYMENT:
      if (data?.signal_data?.checkout_url) {
        redirectInterceptHref(`${data.signal_data.checkout_url}`);
      } else {
        redirectInterceptHref(`/partners/${partner}/forex/listing`);
      }
      break;
    case SIGNAL_STATE.VIEW_ORDERS:
      redirectInterceptHref(`${url}/post-sales/orders-listing`);
      break;
    default:
      // Fallback case for non-loggedin user when cta is empty
      redirectInterceptHref(
        `${url}/onboarding/load-currency${
          data?.orderId ? "?dp_orderId=" + data?.orderId : ""
        }`
      );
      break;
  }
};

const cardTypeHandlers = {
  [CARD_TYPE.MULTI_CURRENCY_CARD]: signalStateRedirectionV3,
  [CARD_TYPE.CURRENCY]: signalStateRedirectionForCurrencyV3,
};

export function startOrderRedirectionsV3(
  item: landingCardShowcaseDataProps,
  { otuForexPartnerUrl, tmBaseUrl, mwebtmBaseUrl, device }: IRedirectUrlOption
) {
  const { nextSignalState, orderId } = extractCommonRedirectionData(
    item,
    PERSUASION_TYPE.START_ORDER
  );
  const handler = item.id ? cardTypeHandlers[item.id] : () => {};
  handler(
    tmBaseUrl,
    mwebtmBaseUrl,
    nextSignalState,
    { orderId },
    otuForexPartnerUrl
  );
}

export function trackCardRedirectionsV3(
  item: landingCardShowcaseDataProps,
  { otuForexPartnerUrl, tmBaseUrl, mwebtmBaseUrl, device }: IRedirectUrlOption
) {
  removeSessionStorageItem(SESSION_STORAGE_KEYS.REQUEST_ID);
  const { nextSignalState, orderId } = extractCommonRedirectionData(
    item,
    PERSUASION_TYPE.DELIVERY_TRACKING
  );
  let queryParam = `deeplink=${deepLink.RELOAD_TRACK_ORDER}${
    orderId ? "&dp_orderId=" + orderId : ""
  }`;
  const handler = item.id ? cardTypeHandlers[item.id] : () => {};
  handler(
    tmBaseUrl,
    mwebtmBaseUrl,
    nextSignalState,
    { queryParam },
    otuForexPartnerUrl
  );
}

export function redirectDropOffV3(
  item: landingCardShowcaseDataProps,
  { otuForexPartnerUrl, tmBaseUrl, mwebtmBaseUrl, device }: IRedirectUrlOption
) {
  const { nextSignalState } = extractCommonRedirectionData(
    item,
    PERSUASION_TYPE.DROP_OFF
  );
  const handler = item.id ? cardTypeHandlers[item.id] : () => {};
  handler(tmBaseUrl, mwebtmBaseUrl, nextSignalState, {}, otuForexPartnerUrl);
}

export function redirectUploadDocsV3(
  item: landingCardShowcaseDataProps,
  { otuForexPartnerUrl, tmBaseUrl, mwebtmBaseUrl, device }: IRedirectUrlOption
) {
  removeSessionStorageItem(SESSION_STORAGE_KEYS.REQUEST_ID);
  const host = getHostName();
  const cnPostSaleBaseUrl = "/bmf/currency/post-sales";
  const { nextSignalState, orderId } = extractCommonRedirectionData(
    item,
    PERSUASION_TYPE.UPLOAD_DOCUMENTS
  );
  const handler = item.id ? cardTypeHandlers[item.id] : () => {};
  handler(
    tmBaseUrl,
    mwebtmBaseUrl,
    nextSignalState,
    { orderId, feature_flag: item.feature_flag },
    otuForexPartnerUrl
  );
}
