import { getCookie, isHostMMT } from "@tm/utility";
import { PRODUCT_TYPE } from "../constants";
import {
  CLIENT_BACKEND_BASE_URL,
  FOREX_API_BASE_URL,
  generateRequestId,
} from "@tm/ui-widgets";

// RESPONSIVE BREAKPOINTS
export const breakpoint = {
  mobile: 768,
};

export const deviceType = {
  mobile: `(max-width: ${breakpoint.mobile}px)`,
  desktop: `(min-width: ${breakpoint.mobile}px)`,
};

// UTILS FUNCTIONS
export const fakeUUID = (length: number = 5): string => {
  var result = "";

  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  const charactersLength = characters.length;

  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

// CALENDAR UTILITY FUNCTIONS
export const randomDayData = () => {
  const min = 10000;
  const max = 15000;
  const randomNum = Math.floor(Math.random() * (max - min + 1) + min);

  return {
    price: `₹${randomNum}`,
    class: randomNum <= 12000 ? "greenText" : "redText",
    isSoldOut: randomNum > 12000 && randomNum < 13000,
    availableOnPreviousDate: randomNum >= 13000 && randomNum <= 13500,
  };
};

export const dayAdditionalData = new Array(31)
  .fill(0)
  .map(() => randomDayData());

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const SHORT_WEEK_DAYS = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thurs",
  "Fri",
  "Sat",
];

export const LONG_WEEK_DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const anyDateCreator = (afterDays: any, dateObj: any) => {
  if (dateObj) {
    const newDateObj = new Date(dateObj.getTime());
    return new Date(newDateObj.setDate(newDateObj.getDate() + afterDays));
  }

  return new Date(new Date().setDate(new Date().getDate() + afterDays));
};

// WILL RETURN ARRAY CONTAINING [dd, mmm, yy, dy]
export const dateArrGenerator = (dateObj: any) => {
  return dateObj
    ? [
        dateObj.getDate(),
        MONTHS[dateObj.getMonth()],
        dateObj.getFullYear().toString().slice(-2),
        LONG_WEEK_DAYS[dateObj.getDay()],
      ]
    : [
        new Date().getDate(),
        MONTHS[new Date().getMonth()],
        new Date().getFullYear().toString().slice(-2),
        LONG_WEEK_DAYS[new Date().getDay()],
      ];
};

// FOR LANDING PAGE LABEL DISPLAY
export const landingDateFormatter = (dateObj: any) => {
  return dateObj
    ? `${dateObj.getDate()} <span class='latoRegular font18 lineHeight10'>${
        MONTHS[dateObj.getMonth()]
      } '${dateObj.getFullYear().toString().slice(-2)}</span>`
    : `${new Date().getDate()} <span class='latoRegular font18 lineHeight10'>${
        MONTHS[new Date().getMonth()]
      } '${new Date().getFullYear().toString().slice(-2)}</span>`;
};

// FOR MODIFY SEARCH LABEL DISPLAY
export const modifyDateFormatter = (dateObj: any) => {
  return dateObj
    ? `${
        SHORT_WEEK_DAYS[dateObj.getDay()]
      }, <span class='latoBlack'>${dateObj.getDate()} ${
        MONTHS[dateObj.getMonth()]
      }</span> ${dateObj.getFullYear().toString().slice(-2)}`
    : `${
        SHORT_WEEK_DAYS[new Date().getDay()]
      }, <span class='latoBlack'>${new Date().getDate()} ${
        MONTHS[new Date().getMonth()]
      }</span> ${new Date().getFullYear().toString().slice(-2)}`;
};

export const mfActiveTypeMatch = {
  0: "Option 1",
  1: "Option 2",
  2: "Option 3",
};

// FOR DISPAYING INSIDE THE CALENDAR
export const calendarLabelFormatter = (dateObj: any) => {
  return dateObj
    ? `${dateObj.getDate()} ${MONTHS[dateObj.getMonth()]}' ${dateObj
        .getFullYear()
        .toString()
        .slice(-2)}`
    : `${new Date().getDate()} ${MONTHS[new Date().getMonth()]}' ${new Date()
        .getFullYear()
        .toString()
        .slice(-2)}`;
};

export const insertBrOnNewLine = (text: string) => {
  return text.replace(/\n/g, "<br/>");
};

insertBrOnNewLine("hello he\nyello");

export const generateSourceSetImageUrl = (
  cdnUrlWidths: Array<number>,
  imgUrl: string,
  width: number
) => {
  let imgWidths = cdnUrlWidths;
  if (!imgWidths || !Array.isArray(imgWidths) || imgWidths.length === 0)
    imgWidths = [width, width * 2, width * 3];

  return imgWidths
    .map((width) => `${imgUrl}?im=Resize=(${width}) ${width}w`)
    .join(",");
};

export const convertDateToUnix = (date: Date) => {
  return Math.floor(new Date(date).getTime() / 1000);
};

export const convertUnixToDate = (unix: number) => {
  return new Date(unix * 1000);
};

export enum DATE_FORMATS {
  "DDD" = "ddd",
  "YY" = "yy",
  "YYYY" = "yyyy",
  "D MMM" = "d MMM",
  "DD/MM/YYYY" = "dd/MM/yyyy",
  "DD MMM YY" = "dd MMM'yy",
}

const MONTHS_SHORT = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const formatDate = (date: Date, format: DATE_FORMATS) => {
  try {
    date = new Date(date);
    switch (format) {
      case DATE_FORMATS["DD MMM YY"]:
        return `${date.getDate()} ${MONTHS_SHORT[date.getMonth()]}'${date
          .getFullYear()
          .toString()
          .substr(-2)}`;
      default:
        return;
    }
  } catch (error) {
    console.log("Error in formatting given date", date, format);
  }
  return;
};

export function replaceHistory(param: string, value: string) {
  const baseUrl = window.location.pathname;
  const searchParams = new URLSearchParams(window.location.search);

  value?.length > 0 ? searchParams.set(param, value) : null;
  const url = `${baseUrl}${
    searchParams.toString().length > 0 ? "?" + searchParams : ""
  }`;
  window.history.replaceState(
    { ...window.history.state, as: url, url: url },
    "",
    url
  );
}

export function replaceHistoryRemoveParams(param: string) {
  const baseUrl = window.location.pathname;
  const searchParams = new URLSearchParams(window.location.search);

  searchParams.delete(param);

  const url = `${baseUrl}${
    searchParams.toString().length > 0 ? "?" + searchParams : ""
  }`;
  window.history.replaceState(
    { ...window.history.state, as: url, url: url },
    "",
    url
  );
}

export const checkIfVaildCardHolder = async () => {
  try {
    const tmAuthValue: string = getCookie("x-tm-auth") || "";
    const myHeaders = new Headers();
    myHeaders.append("x-tm-auth", tmAuthValue);
    myHeaders.append("x-rq-id", generateRequestId());

    const requestOptions: RequestInit = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow" as RequestRedirect,
    };

    const response = await fetch(
      `${FOREX_API_BASE_URL}/${CLIENT_BACKEND_BASE_URL}/check-valid-card-holder?program=FOREX_BMF_MAKEMYTRIP`,
      requestOptions
    );
    const validCardHolderData = await response.json();
    if (validCardHolderData?.success) {
      return validCardHolderData?.is_valid_card_holder;
    }
  } catch (e) {
    console.error("Exchange Rate Not Found", e);
  }
  return false;
};

export const isOnServer = () => {
  if (typeof window === "undefined") {
    return true;
  }
  return false;
};

export const TRIPMONEY_MMT_DOMAIN_NAMES = [
  "tripmoney.makemytrip.com",
  "www.makemytrip.com",
];

export const isHostMakemytrip = () => {
  if (isOnServer()) return false;
  return isHostMMT();
};

const getUtmMedium = (templateType = "") => {
  let utm_medium = "";
  const path = window.location.pathname;
  if (templateType === SEO_TEMPLATE_TYPE.CURRENCY_RATE) {
    utm_medium = SEO_TEMPLATE_TYPE.CURRENCY_RATE;
  } else if (templateType === SEO_TEMPLATE_TYPE.DENOMINATION_SEO) {
    utm_medium = SEO_TEMPLATE_TYPE.DENOMINATION_SEO;
  } else if (path.includes("exchange")) {
    utm_medium = "currency-exchange";
  } else if (path.includes("currency-converter/")) {
    utm_medium = "currency-converter";
  } else if (path.includes("/currency")) {
    utm_medium = "currency";
  }
  return utm_medium;
};

export const getUtmParamsForCalculatorCta = (
  productType: string,
  templateType: string
) => {
  let utm_source = "";
  let utm_medium = "";
  let utm_campaign = "";
  const path = window.location.pathname;
  const pathParams = path?.split("/");
  utm_campaign =
    pathParams[pathParams.length - 1] === "currency"
      ? "home"
      : pathParams[pathParams.length - 1];
  if (productType === PRODUCT_TYPE.CARD) {
    utm_source = "converter-card";
  } else if (productType === PRODUCT_TYPE.CURRENCY) {
    utm_source = "converter-currency";
  }
  utm_medium = getUtmMedium(templateType);
  return `utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`;
};

export const addUtmParamsToUrl = (
  productType: string,
  templateType: string
): string => {
  let utm_source = "",
    utm_medium = "",
    utm_campaign = "";
  let path = window.location.pathname;
  let pathParams = path?.split("/");
  utm_campaign =
    pathParams[pathParams.length - 1] === "currency"
      ? "home"
      : pathParams[pathParams.length - 1];

  utm_medium = getUtmMedium(templateType);
  if (productType === PRODUCT_TYPE.CARD) {
    utm_source = "card-seo";
  } else if (productType === "currency") {
    utm_source = "currency-notes-seo";
  }
  return `utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`;
};

export const SEO_TEMPLATE_TYPE = {
  CURRENCY_RATE: "currency-rate",
  DENOMINATION_SEO: "denomination-seo",
};
