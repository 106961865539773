import { getHostName, getPartner } from "../common/index";

export const AUTHTOKEN =
  process.env["NODE_ENV"] === "production"
    ? "554DIGG7SWt1EHO"
    : "554DIGG7SWt1EHO";
// : 'tXXXkb1UwVZn3n6'; //QA credentials use it when user service pointed to QA

export const ANDROID = "android";
export const IOS = "ios";
export const UNKNOWN = "unknown";

export const MMT = "MMT";
export const GI = "GI";
export const MakeMyTrip = "mmt";
export const GoIbibo = "gi";
export const TripMoney = "tm";
export const MMTGI = "mmtgi";

export const TMAUTH = "tm-auth";
export const GI_MMT_AUTH = "gi-mmt-auth";

export const UserURL = "https://www.tripmoney.com";
export const RedirectURL = "https://www.tripmoney.com/api/auth";

export const HOST = {
  TRIPMONEY: "www.tripmoney.com",
  MAKEMYTRIP: "www.makemytrip.com",
  GOIBIBO: "www.goibibo.com",
  LOCALHOST: "localhost",
  TRIPMONEY_MAKEMYTRIP: "tripmoney.makemytrip.com",
  FOREX_EXPERT: "forexexpert.makemytrip.com",
  FOREX_EXPERT_GI: "forexexpert.goibibo.com",
};

export const SOURCE = {
  TRIPMONEY: "tripmoney",
  MAKEMYTRIP: "makemytrip",
  GOIBIBO: "goibibo",
  LOCALHOST: "localhost",
  FOREX_EXPERT: "forexexpert",
};

const mapiHost = getHostName()?.includes(SOURCE.MAKEMYTRIP)
  ? "https://mapi.makemytrip.com"
  : "https://mapi.goibibo.com";

export const AppSocialLogin =
  process.env["NX_ENV"] === "local"
    ? `${mapiHost}/ext/TripMoney/Forex/appSocialLogin`
    : `${mapiHost}/ext/TripMoney/Forex/appSocialLogin`;

export const COOKIES = {
  MMT_AUTH_KEY: "mmt-auth",
  TM_AUTH_KEY: "x-tm-auth",
  MMT_LOGIN_REDIRECT_KEY: "loginActionDetails",
  TM_MMT_AUTH_KEY: "tm-mmt-auth",
  GI_AUTH_KEY: "OAUTH-GOIBIBO",
  TM_GI_AUTH_KEY: "tm-gi-auth",
  INR_SESSION_COOKIE: "is-initial-landing",
  MMT_DEVICE_ID: "dvid",
  GI_DEVICE_ID: "__gi_vid",
  FOREX_AGENT_AUTH_KEY:
    getPartner() === MakeMyTrip
      ? "mmt-auth-afftmfxmmtforexexp"
      : "mmt-auth-afftmfxgiforexexp",
};

export const APP_SOCIAL_URL = (partner: any) => {
  const hostname = getHostName()?.includes(SOURCE.MAKEMYTRIP)
    ? "https://mapi.makemytrip.com"
    : "https://mapi.goibibo.com";
  return `${hostname}/ext/TripMoney/Forex/appSocialLogin`;
};

export const GET_SLT_TOKEN_URL = (partner: any) => {
  const hostname = getHostName()?.includes(SOURCE.MAKEMYTRIP)
    ? "https://mapi.makemytrip.com"
    : "https://mapi.goibibo.com";
  return `${hostname}/ext/web/pwa/jwt/loginToken`;
};

export const USER_SERVICE_TOKEN =
  process.env["NODE_ENV"] === "production"
    ? "h4nhc9jcgpAGIjp"
    : "h4nhc9jcgpAGIjp";
// : 'cmVzdDoxMjM0NTY='; //QA credentials use when pointed to QA user service

export const USER_SERVICE_TM_AUTH =
  process.env["NODE_ENV"] === "production"
    ? "554DIGG7SWt1EHO"
    : "554DIGG7SWt1EHO";
// : 'tXXXkb1UwVZn3n6'; //QA credentials use when pointed to QA user service

export const TM_AUTH_EXPIRY_DAYS = 89;

export const MMT_LOGIN_URL = "//www.makemytrip.com/pwa/hlp/v3/login";

export const MMT_SUB_DOMAIN = ".makemytrip.com";

export const GI_SUB_DOMAIN = ".goibibo.com";

export const DEVICE_OS = {
  ANDROID: "android",
  IOS: "ios",
  UNKNOWN: "unknown",
};

export const getListingUrl = () =>
  `${window?.location?.protocol}//${
    window?.location?.hostname
  }/partners/${getPartner()}/forex/listing`;

export const getUserIdentifier = (auth: string, deviceId: string) =>
  `{"ipAddress":"ipAddress","imie":"imie","appVersion":"appVersion","os":"DESKTOP","osVersion":"osVersion","timeZone":"timeZone","type":"mmt-auth","profileType":"","Authorization":"${USER_SERVICE_TOKEN}","deviceId":"${deviceId}","cookie":"${auth}","value":"${auth}"}`;

export const getUserIdentifierForSocialLogin = (
  auth: string,
  deviceId: string
) =>
  `{"type" :"auth","value":"","deviceId":"${deviceId}","os":"android","osVersion":"6.0.0","appVersion":"5.4.1","imie":"132342354","ipAddress":"127.0.0.1","timeZone":"+5.30 GMT"}`;
export const DISCOUNT_BANNER_TEXT =
  "Upto <b>INR 5000 cashback</b> on Multicurrency Card & Currency Notes";

export const DISCOUNT_BANNER_REDIRECTION_MMT_URL =
  "https://www.makemytrip.com/promos/forex-cashback.html?utm_source=cashback&utm_medium=mlp";

export const DISCOUNT_BANNER_REDIRECTION_GI_URL =
  "https://www.goibibo.com/offers/tripmoney-forex";

export const MMT_FOREX_URL = "https://www.makemytrip.com/forex";

export const IS_INITOTU_SESSION_KEY = "is_initotu";

export const MMT_URL = "https://www.makemytrip.com";

export const UTM_PARAMS_KEY = {
  UTM_SOURCE: "utm_source",
  UTM_MEDIUM: "utm_medium",
  UTM_CAMPAIGN: "utm_campaign",
};

export const UTM_SOURCE_VALUE = {
  BOTTOM_BAR: "bottombar",
};

export const SUB_DOMAIN = {
  MAKEMYTRIP: ".makemytrip.com",
  GOIBIBO: ".goibibo.com",
  TRIPMONEY: ".tripmoney.com",
  LOCALHOST: "localhost",
};

export const MMT_DESKTOP_DIV = "mmt-login-desktop";

export const CUSTOM_EVENTS = {
  MMT_LOGIN_CUSTOM_EVENT: "mmtlogin",
  MMT_AGENT_LOGIN_CUSTOM_EVENT: "mmtAgentlogin",
  EMAIL_LOGIN_ERROR_EVENT: "emailLoginError",
  APP_UPDATE_MODAL_EVENT: "appUpdateModalEvent",
  LOGIN_ERROR_EVENT: "loginErrorEvent",
};

export const loginHandlerGIDWeb = (
  encodedString: string,
  redirectUrl: string
) => {
  const url = `https://${
    process.env["NODE_ENV"] === "production"
      ? "goibibo.com"
      : "newprodpp.goibibo.com"
  }/login/vendor/?lob=tripmoney&customData=${encodedString}&next=${redirectUrl}`;
  window.location.href = url;
};

export const loginHandlerGIMweb = (
  encodedString: string,
  redirectUrl: string
) => {
  const url = `https://${
    process.env["NODE_ENV"] === "production"
      ? "goibibo.com"
      : "newprodpp.goibibo.com"
  }/login/?lob=tripmoney&customData=${encodedString}&next=${redirectUrl}`;
  window.location.href = url;
};

export const MESSAGES = {
  EMAIL_LOGIN_ERROR: (partnerName: string) =>
    `Please add mobile number in your ${partnerName} profile and continue to book forex`,
  APP_UPDATE: "Please update your app to the latest version to continue.",
  LOGIN_ERROR: "Failed to login. Please try after sometime.",
};

export const PROFILE_URL = {
  MMT_PWA: "https://www.makemytrip.com/pwa/hlp/v3/accountDetails/",
  MMT_DT: "https://www.makemytrip.com/my-profile/",
  GI: "https://www.goibibo.com/profile/",
};

export enum LOGIN_TYPE {
  MOBILE = "MOBILE",
  EMAIL = "EMAIL",
}

export enum ACCOUNT_TYPE {
  MYBIZ = "MYBIZ",
  BUSINESS = "BUSINESS",
}
