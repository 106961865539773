import { getCookie, setCookie, removeCookie } from "../../utils/cookieUtils";
import {
  DEEPLINK,
  PARTNER,
  deepLink,
  SOURCE,
  COOKIES,
  MMT_SUB_DOMAIN,
  GI_SUB_DOMAIN,
  HOST,
  IS_INITOTU_SESSION_KEY,
  MakeMyTrip,
  MMT_FOREX_URL,
  UTM_PARAMS_KEY,
} from "../../constants";
import { isMobile, getHostName } from "../../utils";
import { getSessionStorageItem } from "../../utils/clientStorageUtils";

const {
  UTM_SOURCE,
  UTM_CAMPAIGN,
  UTM_MEDIUM,
  UTM_CONTENT,
  UTM_LENDER,
  UTM_LOGIN,
  UTM_FLOW,
} = UTM_PARAMS_KEY;

export const persistutmParamsInCookie = (
  utm_source?: string,
  utm_medium?: string,
  utm_campaign?: string,
  utm_lender?: string,
  utm_login?: string,
  utm_content?: string,
  utm_flow?: string
) => {
  if (
    !!utm_source &&
    utm_source != null &&
    utm_source !== "video-kycookieCutter.happay.in"
  ) {
    setCookie(UTM_SOURCE, utm_source, false, { path: "/" });
  }
  if (!!utm_medium && utm_medium != null && utm_medium !== "referral") {
    setCookie(UTM_MEDIUM, utm_medium, false, { path: "/" });
  }
  if (!!utm_campaign && utm_campaign != null) {
    setCookie(UTM_CAMPAIGN, utm_campaign, false, { path: "/" });
  }
  if (!!utm_lender && utm_lender != null) {
    setCookie(UTM_LENDER, utm_lender, false, { path: "/" });
  }
  if (!!utm_login && utm_login != null) {
    setCookie(UTM_LOGIN, utm_login, false, { path: "/" });
  }
  if (!!utm_content && utm_content != null) {
    setCookie(UTM_CONTENT, utm_content, false, { path: "/" });
  }
  if (!!utm_flow && utm_flow != null) {
    setCookie(UTM_FLOW, utm_flow, false, { path: "/" });
  }
};

export const getSessionExpiredRedirectUrl = ({
  partner,
  context = "forex",
  action = "",
  postSales = "",
  mobile = "",
  isNewLogin = "",
  deeplink = "",
}: any) => {
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.has(DEEPLINK)) {
    deeplink = urlParams.get(DEEPLINK);
  }

  let deeplinkParamOrderId;

  if (urlParams.has(deepLink.RELOAD_ORDER_ID)) {
    deeplinkParamOrderId = urlParams.get(deepLink.RELOAD_ORDER_ID);
  }

  const deeplinkStr = `${deeplink ? DEEPLINK + "=" + deeplink : ""}${
    deeplink && deeplinkParamOrderId
      ? "&" + deepLink.RELOAD_ORDER_ID + "=" + deeplinkParamOrderId
      : ""
  }`;

  let redirectUrl = `${window?.location?.protocol}//${
    window?.location?.hostname
  }/partners/${partner}/forex/listing${deeplinkStr ? "?" + deeplinkStr : ""}`;

  removeTmMmmtAuthCookie();

  if (partner === MakeMyTrip && !isMobile()) {
    redirectUrl = MMT_FOREX_URL;
  }

  if (!getSessionStorageItem(IS_INITOTU_SESSION_KEY, true)) {
    return redirectUrl;
  }

  // redirectInterceptHref(redirectUrl)

  switch (partner) {
    case PARTNER.MMT:
      //for dev testing on local
      // if (process.env.NODE_ENV !== "production") {
      //     return `http://localhost:3003/ext/api/v1/partners/${partner}/initOTU?product=${context}${!isNewLogin ? "&sessionExpired=true" : ""}&mobile=${!isNewLogin ? mobile : ""}&action=${action ?? ""}&postSales=${postSales ?? ""}`;
      // }
      return `https://lending.makemytrip.com/ext/api/v1/partners/${partner}/initOTU?product=${context}${
        !isNewLogin ? "&sessionExpired=true" : ""
      }&mobile=${!isNewLogin ? mobile : ""}&action=${action ?? ""}&postSales=${
        postSales ?? ""
      }${deeplinkStr ? "&" + deeplinkStr : ""}`;
    case PARTNER.GI:
      return `https://lending.goibibo.com/ext/api/v1/partners/${partner}/initOTUGI?product=${context}${
        !isNewLogin ? "&sessionExpired=true" : ""
      }&mobile=${!isNewLogin ? mobile : ""}&action=${action ?? ""}&postSales=${
        postSales ?? ""
      }${deeplinkStr ? "&" + deeplinkStr : ""}`;
    default:
      return "/error";
  }
};

const removeTmMmmtAuthCookie = () => {
  if (window?.location?.hostname.indexOf(SOURCE.MAKEMYTRIP) > -1) {
    removeCookie(COOKIES.TM_AUTH_KEY, {
      path: "/",
      domain: MMT_SUB_DOMAIN,
    });
    removeCookie(COOKIES.TM_MMT_AUTH_KEY, {
      path: "/",
      domain: MMT_SUB_DOMAIN,
    });
    // persistSpecificParamInCookie(COOKIES.MMT_AUTH_KEY, '', {
    //     path: "/",
    //     domain: MMT_SUB_DOMAIN,
    //     expires: new Date(0)
    // });
  } else if (window?.location?.hostname.indexOf(SOURCE.GOIBIBO) > -1) {
    removeCookie(COOKIES.TM_AUTH_KEY, {
      path: "/",
      domain: GI_SUB_DOMAIN,
    });
    removeCookie(COOKIES.TM_GI_AUTH_KEY, {
      path: "/",
      domain: GI_SUB_DOMAIN,
    });
    // persistSpecificParamInCookie(COOKIES.GI_AUTH_KEY, '', {
    //     path: "/",
    //     domain: GI_SUB_DOMAIN,
    //     expires: new Date(0)
    // });
  }
};

export const fetchutmParamsFromCookie = () => {
  const utm_medium = getCookie(UTM_MEDIUM, false, { path: "/" });
  const utm_source = getCookie(UTM_SOURCE, false, { path: "/" });
  const utm_campaign = getCookie(UTM_CAMPAIGN, false, { path: "/" });
  const utm_lender = getCookie(UTM_LENDER, false, { path: "/" });
  const utm_login = getCookie(UTM_LOGIN, false, { path: "/" });
  const utm_content = getCookie(UTM_CONTENT, false, { path: "/" });
  const utm_flow = getCookie(UTM_FLOW, false, { path: "/" });
  return {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_lender,
    utm_login,
    utm_content,
    utm_flow,
  };
};

export const formUrlForRedirection = (
  utm_source?: string | null,
  utm_medium?: string | null,
  utm_campaign?: string | null,
  utm_lender?: string | null,
  utm_login?: string | null,
  utm_content?: string | null,
  utm_flow?: string | null
) => {
  let urlParam = "";
  if (utm_source) {
    if (!urlParam) {
      urlParam = urlParam + "utm_source=" + utm_source;
    } else {
      urlParam = urlParam + "utm_source=" + utm_source;
    }
  }
  if (utm_medium) {
    if (!urlParam) {
      urlParam = urlParam + "utm_medium=" + utm_medium;
    } else {
      urlParam = urlParam + "&utm_medium=" + utm_medium;
    }
  }
  if (utm_campaign) {
    if (!urlParam) {
      urlParam = urlParam + "utm_campaign=" + utm_campaign;
    } else {
      urlParam = urlParam + "&utm_campaign=" + utm_campaign;
    }
  }
  if (utm_lender) {
    if (!urlParam) {
      urlParam = urlParam + "utm_lender=" + utm_lender;
    } else {
      urlParam = urlParam + "&utm_lender=" + utm_lender;
    }
  }
  if (utm_login) {
    if (!urlParam) {
      urlParam = urlParam + "utm_login=" + utm_login;
    } else {
      urlParam = urlParam + "&utm_login=" + utm_login;
    }
  }

  if (utm_content) {
    if (!urlParam) {
      urlParam = urlParam + "utm_content=" + utm_content;
    } else {
      urlParam = urlParam + "&utm_content=" + utm_content;
    }
  }

  if (utm_flow) {
    if (!urlParam) {
      urlParam = urlParam + "utm_flow=" + utm_flow;
    } else {
      urlParam = urlParam + "&utm_flow=" + utm_flow;
    }
  }

  return urlParam;
};

export const redirectInterceptHref = (url: string, hideParam = false) => {
  const {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_lender,
    utm_login,
    utm_content,
    utm_flow,
  } = fetchutmParamsFromCookie();
  const urlParam = formUrlForRedirection(
    utm_source,
    utm_medium,
    utm_campaign,
    utm_lender,
    utm_login,
    utm_content,
    utm_flow
  );
  const isHostMakeMyTrip = getHostName() === HOST.MAKEMYTRIP;
  if (url && !hideParam) {
    if (url.includes("?")) {
      url = url + "&" + urlParam;
    } else {
      url = url + "?" + urlParam;
    }
  }
  if (isHostMakeMyTrip && !hideParam) {
    if (url.endsWith("&") || url.endsWith("?")) {
      url += "mmt_forex=true";
    } else {
      url += "&mmt_forex=true";
    }
  }
  window.location.href = url;
};
