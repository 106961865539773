import styled, { css, keyframes } from "styled-components";
import { theme } from "../../css/Global";
import { deviceType } from "../../utils";
import { getHostName } from "../../../utils";
import { HOST, VERSIONS } from "../../../constants";
import { ParaTagStyle } from "../ui-atoms/TextStyles/TextStyles.style";

type ListingTopContainerProps = {
  bgImage?: string;
  fullPageHeight?: boolean;
  isPageLoading?: boolean;
  isLocationFetching?: boolean;
  device?: string;
  version?: number;
};

type BMFListingContainerProps = {
  padding?: string;
  margin?: string;
};

type SpecialAttentionProps = {
  withShadow?: boolean;
  margin?: string;
};

type ShowAnimationProps = {
  showAnim?: boolean;
};

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

const fadeOut = keyframes`
    0% { opacity: 1; }
    100% { opacity: 0; }
`;

const moveUpText = keyframes`
    from {
        transform: translateY(50vh);
    }
    to {
        transform: translateY(0px);
    }
`;

const moveUpTextTwo = keyframes`
    from {
        transform: translateY(50vh);
    }
    to {
        transform: translateY(-80px);
    }
`;

const moveAndScale = keyframes`
    from {
        transform: translateY(0px) scale(1);
    }
    to {
        transform: translateY(-100px) scale(0.2);
        opacity: 0;
    }
`;

// ANIMATIONS STARTS
export const FadeInAnimation = styled.div<any>`
  opacity: 0;
  animation: ${fadeIn} linear 0.8s
    ${(props) => (props.delay ? props.delay : "0.5s")};
  animation-fill-mode: forwards;
`;

export const FadeInAndMoveUpAnimation = styled.div<any>`
  transform: ${(props) =>
    props.moveFromDistance
      ? `translateY(${props.moveFromDistance})`
      : "translateY(0px)"};

  ${`
    animation: moveUp ease-out 0.8s;
    ${(props: any) => (props.moveDelay ? props.moveDelay : "0s")};
    animation-fill-mode: forwards;
    @keyframes moveUp {
        0% { transform: translateY(${(props: any) =>
          props.moveFromDistance ? props.moveFromDistance : "0px"}) }
        100% { transform: translateY(0px) }
    }
    `}
`;
// ANIMATIONS ENDS

export const BMFListingContainer = styled.div<BMFListingContainerProps>`
  width: 1200px;
  margin: ${(props) => (props.margin ? props.margin : "0px auto")};
  ${(props) => props.padding && `padding: ${props.padding};`}
  background-color: ${theme.whiteText};

  @media screen and ${deviceType.mobile} {
    width: 100%;
  }
`;

export const ArrowIconContainer = styled.span`
  transform: scale(1);
`;

export const ListingTopContainer = styled.div<ListingTopContainerProps>`
  background-color: ${typeof window !== "undefined" &&
  getHostName() === HOST.MAKEMYTRIP
    ? "none"
    : theme.blackText};

  padding: ${typeof window !== "undefined" && getHostName() === HOST.MAKEMYTRIP
    ? "0px 0px 20px 0px"
    : "100px 0px 20px"};

  @media screen and ${deviceType.mobile} {
    background-image: ${(props) => `url(${props.bgImage})`};
    background-repeat: no-repeat;
    background-size: 100%;
    padding-bottom: unset;
    padding: unset;
    padding-top: 16px;
    background-color: ${(props) =>
      props.version === VERSIONS.V3 ? theme.whiteText : theme.blackText};

    ${(props) =>
      (props.isLocationFetching || props.isPageLoading) &&
      `
            background-color:  ${(props: any) =>
              props.version === VERSIONS.V3
                ? theme.whiteText
                : theme.blackText};
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 106vh;
            background-size: cover;
            background-image: ${(props: any) =>
              props.version === VERSIONS.V3
                ? `url(${props.bgImage})`
                : "url(https://tripmoneycmsimgak.mmtcdn.com/img/listing_bg_grill_fcf0a7f743.svg)}"};
        `}
  }
`;

export const LandingCardFeatureCtr = styled.div<any>`
  display: flex;
  gap: ${(props) => (props.version === VERSIONS.V3 ? "24px" : "6px")};

  @media screen and ${deviceType.mobile} {
    flex-direction: column;
    gap: 8px;
  }
`;
export const MarkerContainer = styled(FadeInAndMoveUpAnimation)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  z-index: 2;

  @media screen and ${deviceType.mobile} {
    padding: 0px 16px 0px;
  }
`;

export const SpecialAttention = styled.div<SpecialAttentionProps>`
  /* padding: 24px; */
  overflow: hidden;
  border-radius: 16px;
  background: white;

  ${(props) =>
    props.withShadow && `box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);`}

  ${(props) => props.margin && `margin: ${props.margin};`}
`;

// Location Fetching Styles
export const LocationLoading = styled.div`
  position: relative;
  width: 208px;
  height: 208px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* animation: ${fadeIn} linear 0.2s; */
  /* opacity: 0;
    animation: ${fadeIn} linear 1s 2s; */
  /* animation-fill-mode: forwards; */
`;

export const LocationLoadingCtr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -90%);
`;

export const AbsIconContainer = styled.div<any>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  /* opacity: 0; */
  /* animation: ${fadeIn} linear 1s 2s; */
  ${(props) =>
    props.isFadeIn &&
    css`
      animation: ${fadeIn} linear 1s;
    `}
`;

export const MoveUpAnimation = styled.div`
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: center;
  transform: translateY(-100px);
  animation: ${moveUpTextTwo} ease-out 1.2s;
  animation-fill-mode: forwards;
`;

export const FadeImg = styled.div<ShowAnimationProps>`
  ${(props) =>
    props.showAnim &&
    css`
      animation: ${fadeOut} linear 1.2s;
      animation-fill-mode: forwards;
    `}
`;

export const MoveUpAndScale = styled.div<ShowAnimationProps>`
  ${(props) =>
    props.showAnim &&
    css`
      animation: ${moveAndScale} linear 1s;
      animation-fill-mode: forwards;
    `}
`;

export const ConditionsText = styled.div<any>`
  margin: auto 0px 50px;
  text-align: center;
  width: 285px;
  transform: translateY(0px);
  animation: ${moveUpText} ease-out 1.2s;
`;

export const GifContainer = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background: ${theme.whiteText};
`;

export const MMTAvatarIcon = styled.span`
  background-image: url(//imgak.mmtcdn.com/pwa_v3/pwa_commons_assets/homePageV3/home-revamp-sprite17.webp);
  background-size: 300px 550px !important;
  background-repeat: no-repeat;
  display: inline-block;
  flex-shrink: 0;
  height: 26px;
  width: 27px;
  margin-left: -4px;
  margin-right: -5px;
  background-position: -241px -142px;
`;

export const MMTAvatarIconV3 = styled.span`
  background: url(https://tripmoneycmsimgak.mmtcdn.com/img/tripmoney_mmtprofile_V3_0e8b64f5a3.png)
    0 0 no-repeat;
  background-size: 32px 32px !important;
  display: inline-block;
  flex-shrink: 0;
  height: 32px;
  width: 32px;
  margin-left: -4px;
  margin-right: -5px;
`;

export const GIAvatarIcon = styled.span`
  height: 30px;
  width: 30px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("data:image/svg+xml,%3Csvg width='29' height='28' viewBox='0 0 29 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='14.2687' cy='14' r='14' fill='%23DEE5EA'/%3E%3Cpath d='M14.2314 5.69614C16.5908 5.69614 18.5032 7.67932 18.5032 10.1262C18.5032 12.573 16.5908 14.5562 14.2314 14.5562C11.8719 14.5562 9.95956 12.573 9.95956 10.1262C9.95956 7.67932 11.8719 5.69614 14.2314 5.69614Z' fill='%23657A97'/%3E%3Cpath d='M14.0814 16.1384C11.1298 16.137 8.39518 17.584 6.88809 19.9477C6.76431 20.1403 6.76431 20.3803 6.88809 20.5729C7.01768 20.7682 7.24629 20.8862 7.49238 20.8848H20.6529C20.899 20.8862 21.1276 20.7682 21.2572 20.5743C21.3824 20.3817 21.3824 20.1417 21.2572 19.9491C19.7559 17.5881 17.0286 16.1397 14.0814 16.1384Z' fill='%23657A97'/%3E%3C/svg%3E%0A");
`;

export const AvatarIconWpr = styled.div`
  position: relative;
  display: inline-block;
`;
export const CoachmarkContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 1000;
`;

export const CoachmarkInnerContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const CounterTag = styled.div`
  font-size: 10px;
  color: ${theme.whiteText};
  position: absolute;
  top: -8px;
  right: -14px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: "Open Sans";
  font-weight: 400;
  background: #eb2026;
`;

export const EffectiveRateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props: { showEffectiveBanner?: boolean }) =>
    props.showEffectiveBanner ? "space-between" : "right"};
  margin: 24px 0px 0px;
`;

export const PriceRatesWrapper = styled.div`
  display: flex;
`;
export const PriceRatesContainerOuter = styled.div`
  border-radius: 8px;
  background: linear-gradient(90deg, #f9f0fb, #eae9f7);
  padding: 1px;
`;
export const PriceRatesContainer = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 7px;
  gap: 4px;
  background: linear-gradient(90deg, #ffffff, #faf1fb);
  padding: 8px 24px 8px 8px;
`;
export const PriceTickerOuter = styled.div`
  border-radius: 8px;
  background: linear-gradient(90deg, #f9f0fb, #eae9f7);
  padding: 1px;
  margin-left: -14px;
`;
export const PriceTickerInner = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px;
  background: #fff;
  padding: 0 24px;
`;

export const LandingTitleStyle = styled(ParaTagStyle)<any>`
  font-size: 14px;
  line-height: 20px;
  color: ${theme.greyText};

  .boldText {
    font-weight: 700;
  }
  .greenText {
    color: ${theme.greenText6};
  }
  .font16 {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const BestPriceTextGradient = styled(ParaTagStyle)<any>`
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  background: -webkit-linear-gradient(120deg, #3023ae, #c86dd7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
export const ZeroPayment = styled.div`
  position: absolute;
  left: 16px;
  top: 0;
  z-index: 1;
`;
